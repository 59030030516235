import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Image, VStack, extendTheme, ChakraProvider, Text, Link } from '@chakra-ui/react';
import { Global, css, keyframes  } from '@emotion/react';
import { FaHeart } from 'react-icons/fa';
import logo from './gmhaa-logo-01.svg';
import { Helmet } from 'react-helmet';

// Define the keyframes for the pulse animation
const pulse = keyframes`
  0%, 100% { transform: scale(1); }
  50% { transform: scale(0.98); }
`;

const pulseOpacity = keyframes`
  0%, 100% { background-color: '#ff0000'; } // Change to your preferred shade of red
  50% { background-color: 'donateBlue.500'; }
`;

const theme = extendTheme({
  components: {
    Button: {
      variants: {
        "animated": {
          bg: 'donateBlue.500',
          color: 'white',
          _hover: {
            transform: 'translateY(-2px)', // Slightly raise the button on hover
            boxShadow: 'lg', // Increase shadow to give a "lifting" effect
          },
          _active: {
            transform: 'translateY(0)',
          },
          animation: `${pulse} 2s infinite ease-in-out, ${pulseOpacity} 2s infinite ease-in-out`,
        },
      },
    },
  },
  colors: {
    donateBlue: {
      500: '#0070f3', // Replace with your preferred blue color
      600: '#0056d2', // A darker shade for hover effects
      300: '#3291ff', // A lighter shade for alternative uses
    },
  },
});

const DonationScreen = () => {

  const [height, setHeight] = useState('100vh'); // initial value

  useEffect(() => {
    // Function to update height state
    const updateHeight = () => {
      setHeight(`${window.innerHeight}px`);
    };

    // Update height on mount and whenever window size changes
    window.addEventListener('resize', updateHeight);
    updateHeight();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', updateHeight);
  }, []); // Empty dependency array ensures effect runs only on mount and unmount

  const handleButtonClick = (url) => {
    window.open(url, '_blank');
  };


  return (
    <ChakraProvider theme={theme}>
      <Global
        styles={css`
        @keyframes pulse {
          0%, 100% { transform: scale(1.1); }
          50% { transform: scale(0.9); }
        },
          html, body {
            height: 100%;
            min-height: 100%;
          }
        `}
      />
      <Helmet>
        <title>Georgia Mental Health Access Alliance</title>
        <link rel="icon" href="https://media.swipepages.com/653fe09b9707d10018b2b4e3%2Ffavicon%2Ffavicon-16x16.png" />
        <meta name="description" content="Healthy minds build healthy communities. Want to make a lasting difference where you live? Join the movement for better mental health." />
        <meta property="og:title" content="Georgia Mental Health Access Alliance" />
        <meta property="og:description" content="Healthy minds build healthy communities" />
        <meta property="og:image" content="https://media.swipepages.com/653fe09b9707d10018b2b4e3%2Fog%2F1376x764-gmhaa.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Georgia Mental Health Access Alliance" />
        <meta name="twitter:description" content="Healthy minds build healthy communities. Our mission is to promote mental health resilience through financial support and easy access to qualified counseling services." />
        <meta name="twitter:site" content="@gmhaa_org" />
        <meta name="twitter:creator" content="@gmhaa_org" />
        <meta name="twitter:image" content="https://media.swipepages.com/653fe09b9707d10018b2b4e3%2Ftwittercard%2F1376x764-gmhaa.png" />
        <meta name="keywords" content="mental health, counseling, georgia, nonprofit, community, partners" />
      </Helmet>
      <Box backgroundColor="gray.50" minHeight={height}>
        <Box border="1px" borderColor="gray.200" borderRadius="md" backgroundColor="white" p={6} boxShadow="md" m={4} maxWidth={["full", "md"]} width={["90%", "100%"]} mx="auto">
          <Flex direction="column" justify="space-between" align="center" minHeight={["76vh", "90vh"]}>
            <VStack spacing={8} align="center" marginTop={["-16", "-40"]}>
              <Image src={logo} alt="Georgia Mental Health Access Alliance" boxSize={["280px", "500px"]} objectFit="contain" />
              <Text fontSize={["xl", "2xl"]} color="gray.600" fontWeight="medium" textAlign="center" marginTop={["-10", "-36"]}>
              Healthy minds build healthy communities. Want to make a lasting difference where you live? Join the movement for better mental health. Your involvement will change lives.
              </Text>
              {/*
              <Text fontSize={["xl", "2xl"]} color="gray.600" fontWeight="medium" textAlign="center" marginTop={["0", "0"]}>
              For a limited time, a generous partner will match your donation!
              </Text>
              */}
            </VStack>
            <VStack spacing={4} width="full" marginBottom={6}>
              {/*
              <Button colorScheme="donateBlue" width="full" maxWidth={["full", "600px"]} height="60px" fontSize="xl" onClick={() => handleButtonClick('https://donate.stripe.com/fZe5kych2gmTfcsaEE')}>
                Donate
              </Button>
              */}
              <Button 
                rightIcon={<FaHeart sx={{ animation: `${pulse} 2s infinite` }} />}
                variant="animated" 
                width="full" 
                maxWidth={["full", "600px"]} 
                height="60px" 
                fontSize="xl" 
                onClick={() => handleButtonClick('https://www.gmhaa.org/?form=UPLIFTIMPACT2024')}
                sx={{ 
                  position: 'relative',
                  overflow: 'hidden',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    top: -2,
                    right: -2,
                    bottom: -2,
                    left: -2,
                    backgroundImage: 'linear-gradient(0deg, #ff7f7f, #ff0000)',
                    zIndex: -1,
                    animation: `${pulseOpacity} 2s infinite`,
                    boxShadow: '0 0 0 2px currentColor',
                  },
                }}
              >
                Donate Now
              </Button>
              <Link color="gray.400" href="https://www.gmhaa.org/" isExternal marginTop={2}>
                Visit Our Website
              </Link>
            </VStack>
          </Flex>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default DonationScreen;
